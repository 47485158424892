.m-mood-card {
  align-items: center;
  column-gap: var(--space-1);
  display: grid;
  grid-auto-columns: 5em 1fr auto;
}

.m-mood-card > :first-child:last-child {
  grid-column-end: span 3;
}

.m-mood-card__exploration {
  grid-column-start: 2;
  line-height: 1;
  word-break: break-word;
}

.m-mood-card__heading {
  line-height: 1;
  margin-bottom: 0;
}

.m-mood-card__time {
  line-height: 1;
  margin-bottom: 0;
}
