.m-icon {
  --m-icon-color: hsl(210, 100%, 22%);
}
feDropShadow {
  flood-color: #000;
}

@media (prefers-color-scheme: dark) {
  .m-icon {
    --m-icon-color: hsl(195, 100%, 66.7%);
  }
  feDropShadow {
    flood-color: hsl(195, 100%, 50%);
  }
}
