.m-nav__header {
  margin: var(--space-3) var(--space-3) 0;
}

.m-nav__sign-out {
  margin-block: var(--space-2);
}

.m-nav__sync-state {
  margin: var(--space-3) var(--space-3) calc(-1 * var(--space-4));
}

@media (min-width: 66rem) {
  .m-nav__sync-state {
    margin-bottom: calc(-1 * var(--space-3));
  }
}

.m-profile {
  display: grid;
  grid-column-gap: var(--space-2);
  grid-template-columns: 2.5em 1fr;
}

.m-profile > .m-icon {
  grid-row-end: span 2;
}

.m-profile__email {
  font-size: var(--font-size-0);
  overflow: hidden;
  text-overflow: ellipsis;
}

.m-profile__state {
  align-self: flex-end;
}
