.m-year__calendar-button {
  transition-property: background-color;
}
.m-year__calendar-button:hover {
  background: var(--color-ground-less);
}
.m-year__calendar-button:active {
  background: var(--color-highlight-default);
}
.m-year__calendar-button h4 {
  margin-top: 0;
}

.m-year__calendar-grid {
  align-items: start;
  display: grid;
  grid-gap: var(--space-0);
  grid-template-columns: repeat(auto-fit, minmax(8em, 1fr));
}
